.ImageSlider {
  min-height: 80vh;
  position: relative;
  &-dots {
    width: 100%;
    position: absolute;
    top: 4px;
    display: flex !important;
    list-style-type: none;
    padding-left: 0;
    justify-content: center;
    z-index: 999;
    .slick-active {
      .ImageSlider-dot {
        background-color: #ba0b87; } } }
  &-dot {
    border: 1px solid #ba0b87;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    cursor: pointer;
    background-color: #FFF;
    margin-right: 6px;
    margin-left: 6px; }
  .slick-list {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; }
  .slick-list, .slick-track {
    height: 100%; }
  .slick-slide {
    > div {
      height: 100%;
      > div {
        background-position: center;
        background-size: cover; } } } }
