.ProfileImages {
  &-image {
    width: 175px;
    height: 200px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    &:hover {
      .ProfileImages-image {
        &_delete {
          transform: scale(1) !important; } } }
    &_delete {
      min-width: 0px !important;
      padding: 4px !important;
      line-height: 0 !important;
      background: #fff !important;
      transition: transform 0.15s ease-out !important;
      transform: scale(0) !important;
      position: absolute !important;
      top: 6px !important;
      right: 6px !important;
      z-index: 1; } } }

@media (max-width: 600px) {
  .ProfileImages {
    &-image {
      width: calc(50vw - 20px);
      height: 220px;
      &:hover {
        .ProfileImages-image {} }
      &_delete {
        min-width: 0px !important;
        padding: 4px !important;
        line-height: 0 !important;
        background: #fff !important;
        transition: transform 0.15s ease-out !important;
        transform: scale(1) !important;
        position: absolute !important;
        top: 6px !important;
        right: 6px !important;
        z-index: 1; } }
    &_uploader {
      width: calc(50vw - 20px) !important;
      height: 220px !important; } } }
