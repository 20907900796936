.SignupForm {
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.49);
  border-radius: 30px;
  padding: 19px 64px 24px;
  background-color: #fff;
  z-index: 10;
  position: relative;
  &-container {
    position: relative;
    margin: 50px auto 29px; }
  &-logo {
    margin: 0px auto 17px;
    padding: 12px;
    border-bottom: 1px solid #ddd;
    display: block; }
  &-input {
    display: block !important;
    margin-bottom: 8px !important;
    min-width: 320px !important;
    width: 100% !important;
    > div {
      width: 100% !important; }
    input {
      min-width: 320px !important;
      width: 100% !important; } }
  &-submit {
    margin-top: 24px !important;
    width: 100% !important;
    box-shadow: none !important; }
  &-choice {
    margin-bottom: 12px !important; }
  &-stage {
    max-width: 350px; }
  &-disclaimer {
    max-width: 350px !important;
    display: block !important;
    margin: 32px auto 0px !important;
    text-align: center !important; }
  &-feature {}
  &-loader {
    width: 100%;
    min-width: 350px;
    height: 375px;
    display: flex;
    align-items: center;
    justify-content: center; }
  &-or {
    color: #7a7a7a;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin: 32px auto 22px;
    font-size: 13px;
    &::before {
      content: '';
      display: block;
      margin-top: 3px;
      margin-right: 20px;
      width: 80px;
      height: 1px;
      background: #aeaeae; }
    &::after {
      content: '';
      display: block;
      margin-top: 3px;
      margin-left: 20px;
      width: 80px;
      height: 1px;
      background: #aeaeae; } }
  &-finish-btn {
    margin-top: 48px;
    width: 320px !important; } }

@media (max-width: 600px) {
  .SignupForm {
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 16px 42px 24px;
    margin: 0px;
    &-container {
      width: 100%;
      position: relative;
      margin: 0px; }
    &-input {
      margin-bottom: 8px !important;
      min-width: 200px !important;
      width: 100% !important;
      > div {
        width: 100% !important; }
      input {
        width: 100% !important; } }
    &-logo {
      margin: 19px auto 36px;
      padding: 12px;
      border-bottom: 1px solid #ddd;
      display: block; }
    &-submit {
      margin-top: 24px !important;
      width: 100% !important;
      box-shadow: none !important; }
    &-loader {
      width: 100%;
      height: 375px;
      display: flex;
      align-items: center;
      justify-content: center; }
    &-recover {
      display: block;
      text-align: center;
      margin-top: 15px;
      font-size: 13px;
      color: #737373;
      margin: 15px auto 0; }
    &-choice {
      margin-bottom: 12px !important;
      width: 100% !important; }
    &-stage {
      max-width: 100%; }
    &-finish-btn {
      margin-top: 48px;
      width: 100% !important; }
    .SiteInfo {
      margin-top: auto !important; } } }
