.AnimatedPhotos {
  &_1, &_2, &_3, &_4 {
    height: 460px;
    position: absolute;
    top: 54px;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: linear; }
  &_1 {
    left: 0px;
    z-index: 4;
    transform: rotate(0);
    animation-delay: 1s;
    animation-name: girl-1; }
  &_2 {
    left: 0px;
    z-index: 2;
    animation-delay: 1.3s;
    animation-name: girl-2;
    transform: rotate(0); }
  &_3 {
    right: 0px;
    z-index: 3;
    transform: rotate(0);
    animation-delay: 1.1s;
    animation-name: girl-3; }
  &_4 {
    right: 0px;
    z-index: 1;
    animation-delay: 1.4s;
    animation-name: girl-4;
    transform: rotate(0); } }

@media (max-width: 600px) {
  .AnimatedPhotos {
    &_1, &_2, &_3, &_4 {
      display: none; } } }


@keyframes girl-1 {
  from {
    left: 0px;
    transform: rotate(0); }
  to {
    left: -150px;
    transform: rotate(-16deg); } }

@keyframes girl-2 {
  from {
    left: 0px;
    transform: rotate(0); }
  to {
    left: -150px;
    transform: rotate(-6deg); } }

@keyframes girl-3 {
  from {
    right: 0px;
    transform: rotate(0); }
  to {
    right: -150px;
    transform: rotate(16deg); } }

@keyframes girl-4 {
  from {
    right: 0px;
    transform: rotate(0); }
  to {
    right: -150px;
    transform: rotate(7deg); } }
