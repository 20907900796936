.InitialPhotoUpload {
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.49);
  min-width: 320px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 29px;
  padding: 36px;
  background-color: #fff;
  &-container {
    background-image: url(../../assets/signup-bg.png), linear-gradient(180deg, #be0200, #bd4200,#bd4200);
    background-size: 1000px;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center; }
  &--loading {
    justify-content: center; } }

@media (max-width: 600px) {
  .InitialPhotoUpload {
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 24px 16px;
    margin: 0px; } }
